<template>
  <FormInputSelectWidget :notRequired="notRequired" :label="label" :hideLabel="hideLabel" :hasError="hasError" :errorMsg="errorMsg" :options="wineTypeOptions" :value="value" @input="$emit('input', $event)" />
</template>

<script>

import formInputWidgetMixin from '@/libs/Form/components/Inputs/mixins/formInputWidget.js';

export default {
  mixins: [formInputWidgetMixin],
  components: {
    FormInputSelectWidget: () => import('@/libs/Form/components/Inputs/Widgets/FormInputSelectWidget.vue'),
  },
  props: {
    wineTypes: {
      type: Array,
      default: null,
    },
  },
  data () {
    return {
      allWineType: null,
    };
  },
  computed: {
    completeWineType () {
      return this.wineTypes || this.allWineType || [];
    },
    wineTypeOptions () {
      const wineTypeOptions = this.completeWineType.map(p => {
        p.value = p.id;
        p.label = p.name;

        return p;
      });

      if (!this.notRequired) {
        return wineTypeOptions;
      }

      return [
        {
          value: null,
          label: '',
        },
        ...wineTypeOptions,
      ];
    },
  },
  mounted () {
    if (null === this.wineTypes) {
      this.$api.listWineTypes()
        .then(res => {
          this.allWineType = res.data.wineTypes;
        })
        .catch(this.$log.error)
      ;
    }
  },
};

</script>
