<template>
  <FormContainer @submitForm="submit">
    <FeedbackAlert v-if="feedback === false">Errore nel salvataggio dei dati! Riprovare</FeedbackAlert>

    <div class="row">
      <FormInputTextWidget
        class="col-6"
        label="Nome"
        v-model="internalValue.name"
        :errorMsg="errorHash.name"
        @input="removeFieldError('name')"
      />

      <SelectProducerWidget
        class="col-md-6"
        label="Produttore"
        v-model="internalValue.producerId"
        :errorMsg="errorHash.producerId"
        @input="removeFieldError('producerId')"
      />
    </div>

    <div class="row">
      <div class="col-md-6">
        <FormLabel label-is-fake not-required>Caratteristiche</FormLabel>

        <FormInputCheckbox
          v-model="internalValue.enabled"
          @input="removeFieldError('enabled')"
        >In vendita</FormInputCheckbox>

        <FormInputCheckbox
          v-model="internalValue.sparklingWine"
          @input="removeFieldError('sparklingWine')"
        >Spumante (Si applicano le accise per spumanti)</FormInputCheckbox>
      </div>

      <SelectWineTypeWidget
        class="col-md-6"
        label="Tipologia"
        v-model="internalValue.typeId"
        :errorMsg="errorHash.typeId"
        @input="removeFieldError('typeId')"
      />
    </div>

    <div class="row">
      <div class="col-12 mt-4 text-end">
        <button class="btn btn-primary px-5 font-weight-bold">Salva</button>
      </div>
    </div>
  </FormContainer>
</template>

<script>

import validateMixin from '@/libs/Form/mixins/validate.js';

import FormInputTextWidget from '@/libs/Form/components/Inputs/Widgets/FormInputTextWidget.vue';
import FormInputCheckbox from '@/libs/Form/components/Inputs/FormInputCheckbox.vue';
import FormLabel from '@/libs/Form/components/FormLabel.vue';
import SelectWineTypeWidget from './Widgets/SelectWineTypeWidget.vue';
import SelectProducerWidget from './Widgets/SelectProducerWidget.vue';
import FeedbackAlert from '@/libs/Feedback/components/Alert.vue';
import FormContainer from '@/views/components/Form/FormContainer.vue';

export default {
  mixins: [validateMixin],
  components: {
    FormContainer,
    FormLabel,
    FormInputTextWidget,
    FormInputCheckbox,
    SelectWineTypeWidget,
    SelectProducerWidget,
    FeedbackAlert,
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        enabled: true,
        name: null,
        producerId: null,
        typeId: null,
        sparklingWine: false,
      }),
    },
    edit: {
      type: Boolean,
      default: false,
    },
    feedback: {
      type: Boolean,
      default: null,
    },
  },
  data () {
    return {
      iValue: { ...this.value },
    };
  },
  computed: {
    internalValue: {
      get () {
        return this.iValue;
      },
      set (value) {
        this.iValue = value;
      },
    },
  },
  watch: {
    value: {
      handler (newValue) {
        this.iValue = { ...newValue };
      },
      immediate: true,
    },
  },
  methods: {
    removeFieldError (field) {
      this.removeFeedback();
      this.removeError(field);
    },
    removeFeedback () {
      this.$emit('update:feedback', null);
    },
    isValid () {
      this.clearErrors();
      this.removeFeedback();

      if (!this.iValue.name) {
        this.addError('Inserisci il nome', 'name');
      }

      if (!this.iValue.producerId) {
        this.addError('Seleziona il produttore', 'producerId');
      }

      if (!this.iValue.typeId) {
        this.addError('Seleziona la tipologia', 'typeId');
      }

      return !this.hasErrors;
    },
    submit () {
      if (!this.isValid()) {
        return;
      }

      const valueToReturn = { ...this.iValue };

      this.$emit('submitForm', valueToReturn);
    },
  },
};

</script>
